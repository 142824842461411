import React, { useMemo, useEffect, useState } from 'react';
import './index.css';
import { useLocation } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const MapPage = () => {
    const location = useLocation();
    const { selectedJobs } = location.state || {};

    const selectedAddresses = useMemo(() => {
      return [...new Set(selectedJobs.map((job) => job.job_site_address))];
    }, [selectedJobs]);

    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);

    useEffect(() => {
      if (map && markers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
  
        markers.forEach((marker) => {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        });
  
        map.fitBounds(bounds);
      }
    }, [map, markers]);

    useEffect(() => {
      const geocodeAddresses = async () => {
        try {
          const results = await Promise.all(selectedAddresses.map(async (address) => {
            try {
              const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyBRqcr-tkTtADglhY77DNx0N_K-nFwrbTM`);
              
              if (!response.ok) {
                throw new Error(`Error fetching data for ${address}: ${response.statusText}`);
              }
  
              const data = await response.json();
  
              if (data.status !== 'OK') {
                throw new Error(`Geocoding failed for ${address}: ${data.status}`);
              }
  
              const location = data.results[0].geometry.location;
              return { lat: location.lat, lng: location.lng, address };
            } catch (error) {
              console.error('Geocoding error:', error);
              return null; // Return null if there was an error with this address
            }
          }));
  
          // Filter out any null results (those that failed to fetch)
          setMarkers(results.filter(marker => marker !== null));
        } catch (error) {
          console.error('Failed to geocode addresses:', error);
        }
      };
      console.log(selectedAddresses)  
      geocodeAddresses();
    }, [ selectedAddresses]);

    const Table = ({ jobs }) => {
      return (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((row, index) => (
              <tr key={row.id}>
                <td>{index}</td>
                <td>{row.job_site_address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    return (
      <div className='parent-div'>
        <LoadScript
          googleMapsApiKey="AIzaSyBRqcr-tkTtADglhY77DNx0N_K-nFwrbTM"
          loadingElement={<div>Loading...</div>}
          containerElement={<div style={{ height: '400px', width: '100%' }} />}
          mapElement={<div style={{ height: '400px%', width: "100%" }} />}
        >
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            onLoad={(mapInstance) => setMap(mapInstance)}
            zoom={10}
          >
            {markers.map((marker, index) => {
              console.log(marker)
              return (<Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                onClick={() => setSelectedMarker(marker)}
              />
            )})}
            {selectedMarker && (
              <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div>
                  <h4>{selectedMarker.address}</h4>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
        <div>
            <Table jobs={selectedJobs} />
        </div>
      </div>
    );
};

export default MapPage;

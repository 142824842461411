import { jwtDecode } from "jwt-decode";

// Function to get the user ID from the JWT token
export const getUserIdFromToken = () => {
  const token = localStorage.getItem('access_token'); // Adjust the key if needed
  if (!token) {
    return null;
  }

  try {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    return decodedToken.user_id; // Adjust the property name based on your token's payload structure
  } catch (error) {
    console.error('Invalid token:', error);
    return null;
  }
};

export const parseTimeWindow = (timeWindowStr) => {
  if (typeof timeWindowStr === 'Undefined') return { date: '', startTime: '', endTime: '' };


  if (!timeWindowStr || typeof timeWindowStr !== 'string') {
    return { date: '', startTime: '', endTime: '' };
  }

  try {
    const timeWindow = JSON.parse(timeWindowStr);
    return {
        date: timeWindow.date || '',
        startTime: timeWindow.start_time || '',
        endTime: timeWindow.end_time || ''
    };
  } catch (e) {
      console.error('Failed to parse time window:', e);
      return { date: '', startTime: '', endTime: '' };
  }
};
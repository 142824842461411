import React, { useEffect, useState, useCallback  } from 'react';
import './index.css';
import { ToastContainer, toast } from 'react-toastify';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getUserIdFromToken } from '../../components/utils';

const DaysTable = ({ employee, year, month, filledDatesCallback  }) => {

    console.log('DaysTable employee prop:', employee);
    console.log('DaysTable year prop:', year);
    console.log('DaysTable month prop:', month);
    
    const daysInMonth = new Date(year, month, 0).getDate();
    const [modifiedRows, setModifiedRows] = useState({});

    // Generate an array of days with their respective day of the week
    const days = Array.from({ length: daysInMonth }, (_, i) => {
      const date = new Date(year, month, i + 1);
      return {
        date: `${date.getFullYear()}-${String(date.getMonth()).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
        weekday: date.toLocaleDateString("en-US", { weekday: "short" }).toUpperCase(),
      };
    });

    // Memoize fetchData using useCallback to prevent it from being recreated on every render
    const fetchData = useCallback(async () => {
        setInputs({}); // Clear inputs while fetching new data
        setModifiedRows({}); // Clear modified rows while fetching new data
        try {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        console.log('fetching days');
        const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/employee-days/?year=${year}&month=${month}&employee=${employee}`, {
            method: 'GET',
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            const prefilledInputs = data.reduce((acc, entry) => {
            const { id, date, hours, extra, materials, location, description } = entry;
            acc[date] = {
                id: id,
                first: hours,
                second: extra,
                third: materials,
                fourth: location,
                fifth: description,
                isOriginal: true, // Mark as existing
            };
            return acc;
            }, {});
            setInputs(prefilledInputs); // Populate inputs with fetched data

            const filledDates = data.map(({ date }) => date.split("-"));
            filledDatesCallback(filledDates);
            console.log(prefilledInputs)    
        } else {
            console.error('Failed to fetch data', response.statusText);
        }
        } catch (error) {
        console.error('Error fetching data', error);
        }
    }, [employee, year, month]);  // Include dependencies in useCallback



    useEffect(() => {
        console.log(month)
        console.log(year)
        fetchData();
    }, [fetchData, month, year]);
    
    // State to manage the inputs
    const [inputs, setInputs] = useState({});

    // Handle input change
    const handleInputChange = (day, field, value) => {
        setInputs((prevInputs) => ({
        ...prevInputs,
        [day]: {
            ...prevInputs[day],
            [field]: value,
            isOriginal: prevInputs[day]?.isOriginal || false,  // Keep track of existing status
        },
        }));

        setModifiedRows((prevModifiedRows) => ({
            ...prevModifiedRows,
            [day]: true,
          }));
    };


        const handleSubmit = async () => {
            const updates = [];
            const newEntries = [];
            console.log(modifiedRows)
          
            Object.keys(inputs).forEach((day) => {
              const { id, first, second, third, fourth, fifth, isOriginal } = inputs[day] || {};
              console.log(modifiedRows[day])
              if (modifiedRows[day] === true && (first || second || third || fourth || fifth)) {
                const rowData = {
                  id: id,
                  employee: employee,
                  date: day,
                  hours: first || null,
                  extra: second || null,
                  materials: third || null,
                  location: fourth || null,
                  description: fifth || null,
                };
                
                // Check if the row exists in the prefilled data
                if (isOriginal) {
                  console.log(inputs[day])
                  // Add to updates (will be sent with PUT)
                  updates.push(rowData);
                } else {
                  // Add to newEntries (will be sent with POST)
                  newEntries.push(rowData);
                }
              }
            });
          
            // PUT existing modified rows
            for (const update of updates) {
              try { 
                const token = localStorage.getItem('access_token');
                await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/employee-days/${update.id}/`, {
                  method: "PATCH",
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                  },
                  body: JSON.stringify(update),
                });
              } catch (error) {
                console.error("Error updating data", error);
              }
            }
          
            // POST new rows
            if (newEntries.length > 0) {
              try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/employee-days/`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                  },
                  body: JSON.stringify(newEntries),
                });
          
                if (response.ok) {
                  const result = await response.json();
                  console.log("Data successfully sent", result);
                } else {
                  console.error("Failed to send data", response.statusText);
                }
              } catch (error) {
                console.error("Error sending data", error);
              }
            }
            fetchData();
          };          

    // Calculate the sum of the first two inputs
    const calculateSum = (day) => {
        const first = parseFloat(inputs[day]?.first || 0);
        const second = parseFloat(inputs[day]?.second || 0);
        return first + second;
    };

    const calculateTotalForColumn = (column) => {
        return days.reduce((sum, { date }) => {
          const value = parseFloat(inputs[date]?.[column]) || 0;
          return sum + value;
        }, 0);
      };
      console.log(inputs)
    return (
    <div className="emp-info-container">
      <table className="days-table">
        <thead>
          <tr>
            <th>DOW</th>
            <th>DAY</th>
            <th>HOURS</th>
            <th>EXTRA$</th>
            <th>TOTAL</th>
            <th>MAT'L</th>
            <th>LOCATION</th>
            <th>DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          {days.map(({ date, weekday }) => (
            <tr key={date}>
              <td className='info'>{weekday}</td>
              <td className='info'>{date}</td>
              <td >
                <input
                    type="number"
                    value={inputs[date]?.first || ""}
                    onChange={(e) =>
                        handleInputChange(date, "first", e.target.value)
                    }
                    className={modifiedRows[date] ? 'modified' : ''}
                />
              </td>
              <td>
                <input
                    type="number"
                    value={inputs[date]?.second || ""}
                    onChange={(e) =>
                        handleInputChange(date, "second", e.target.value)
                    }
                    className={modifiedRows[date] ? 'modified' : ''}
                    />
              </td >
              <td className='info'>{calculateSum(date)}</td>
              <td>
                <input type="number" 
                    value={inputs[date]?.third || ""}
                    onChange={(e) =>
                        handleInputChange(date, "third", e.target.value)
                    }
                    className={modifiedRows[date] ? 'modified' : ''}
                />
              </td>
              <td>
                <input type="text"
                    value={inputs[date]?.fourth || ""}
                    onChange={(e) =>
                        handleInputChange(date, "fourth", e.target.value)
                    }
                    className={modifiedRows[date] ? 'modified location' : 'location'}
                />    
              </td>
              <td>
                <input type="text" 
                    className={modifiedRows[date] ? 'description modified' : 'description'}
                    value={inputs[date]?.fifth || ""}
                    onChange={(e) =>
                        handleInputChange(date, "fifth", e.target.value)
                    }
                />
                    
              </td>
            </tr>
          ))}
          <tr>
                <td colSpan="2" className='info'>Total</td>
                <td className='info'>{calculateTotalForColumn("first")}</td>
                <td className='info'>{calculateTotalForColumn("second")}</td>
                <td className='info'>{calculateTotalForColumn("total")}</td>
                <td className='info'>{calculateTotalForColumn("third")}</td>
            </tr>
        </tbody>
      </table>      
      <button className={"submit-info"} onClick={handleSubmit}>Submit</button>
    </div>
    );
  };

const EmployeePage = () => {
    const [showModal, setShowModal] = useState(false);
    const [employeeName, setEmployeeName] = useState("");
    const [hourlyRate, setHourlyRate] = useState("");
    const [selectedValue, setSelectedValue] = useState("0");
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(selectedDate.getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
    const [monthlyTotal, setMonthlyTotal] = useState(0);
    const [yearlyTotal, setYearlyTotal] = useState(0);
    const [monthlyMaterials, setMonthlyMaterials] = useState(0);
    const [yearlyMaterials, setYearlyMaterials] = useState(0);

    const [dataFromChild, setDataFromChild] = useState({});

     // Memoize fetchData using useCallback to prevent it from being recreated on every render
     const fetchMonthlyTotal = useCallback(async () => {
        try {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        console.log('fetching days');
        const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/employee-days/aggregate/monthly/?year=${selectedYear}&month=${selectedMonth}&employee=${selectedEmployee}`, {
            method: 'GET',
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data)
            setMonthlyTotal(data.total_payment);
            setMonthlyMaterials(data.total_materials);
        } else {
            console.error('Failed to fetch data', response.statusText);
        }
        } catch (error) {
        console.error('Error fetching data', error);
        }
    }, [selectedMonth, selectedYear, selectedEmployee]);  // Include dependencies in useCallback

     // Memoize fetchData using useCallback to prevent it from being recreated on every render
     const fetchYearlyTotal = useCallback(async () => {
        try {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        console.log('fetching days');
        const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/employee-days/aggregate/yearly/?year=${selectedYear}&employee=${selectedEmployee}`, {
            method: 'GET',
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data)
            setYearlyTotal(data.total_payment);
            setYearlyMaterials(data.total_materials);
        } else {
            console.error('Failed to fetch data', response.statusText);
        }
        } catch (error) {
        console.error('Error fetching data', error);
        }
    }, [selectedYear, selectedEmployee]);  // Include dependencies in useCallback

    const fetchEmployees = useCallback(async () => {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/employees/?format=json`, {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            console.log(response)
            if (!response.ok) {
                throw new Error('Failed to fetch employees');
            }
            return response.json();
        })
        .then(data => {
            setEmployees(data); // Assuming your API returns an array of clients
            setSelectedEmployee(data[0].id);
        })
        .catch(error => {
            if (error.code == 'token_not_valid') {
                logout();
            }

            let errorMessage = '';

            Object.keys(error).forEach(key => {
                // Check if the property is an array and has at least one message
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    // Append the first error message of each field to the errorMessage string
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            notify(`Error fetching employees: ${errorMessage}`);
            console.error('Error fetching employees:', error);
        });
    }, []);    

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch clients from your API and update state
                await fetchEmployees();
                // Fetch monthly total after employees have been fetched
                await fetchMonthlyTotal();

                await fetchYearlyTotal();
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
    
        fetchData();
    }, [fetchEmployees, fetchMonthlyTotal, fetchYearlyTotal]);

     // This function will be passed to the child as a prop
    const handleChildDates = (data) => {
        setDataFromChild(data);
    };

    const disableDates = ({ date, view }) => {
        // Disable all dates when view is 'month'
        if (view === 'month') {
          return true;
        }
        return false;
      };

    const handleShow = (event) => {
        event.preventDefault();
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
        console.log("Selected Value:", selectedValue);
        if (selectedValue === "add-employee") {
            setShowModal(true);
        } else {
            setSelectedEmployee(event.target.options[event.target.selectedIndex].getAttribute('data-id'));
        }
    }

    const handleClose = () => {
        setSelectedValue(employees[0].id);
        setShowModal(false);
    }

    const notify = (text) => toast(text);

    const logout = () => {
        // Remove the tokens from localStorage or any other storage you are using
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    
        // Redirect the user to the login page or home page as required
        window.location.href = '/login';
    };

    const handleSave = () => {
        const token = localStorage.getItem('access_token');
        const url = `${window.location.protocol}//${window.location.hostname}:8443/api/employees/`; // Adjust the URL for adding a new job
        const method = 'POST'; 
    
        fetch(url, { // Adjust this URL to your jobs endpoint
            method: method,
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
            body: JSON.stringify({user:getUserIdFromToken(), name: employeeName, hourly_rate: hourlyRate}),
        })
        .then(response => {
            if (!response.ok) {
                // Convert non-2xx HTTP responses into errors so the catch block can handle them
                return response.json().then(data => Promise.reject(data));
            }
            return response.json();
        })
        .then(data => {
            notify(`Successfully Saved!`);
            fetchEmployees();
            handleClose();
        })
        .catch(error => {
            let errorMessage = '';
    
            Object.keys(error).forEach(key => {
                // Check if the property is an array and has at least one message
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    // Append the first error message of each field to the errorMessage string
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });
    
            if (error.code == 'token_not_valid') {
                logout();
            }
    
            notify(`Error adding job: ${errorMessage}`);
            console.error('Error adding job:', error);
        });
    };

    const handleMonthNavigation = ({ activeStartDate }) => {
        setSelectedDate(activeStartDate);

        console.log(activeStartDate.getFullYear(), "Month: ", activeStartDate.getMonth());

        setSelectedMonth(activeStartDate.getMonth() + 1);
        setSelectedYear(activeStartDate.getFullYear());
    };
    
     // Function to add 'blue-date' class to specific dates
    const highlightBlueDates = ({ date, view }) => {
        console.log(date)

        console.log(Object.values(dataFromChild))

        if (Object.values(dataFromChild).length === 0) {
            console.warn('dataFromChild is empty.');
            return null;  // Return early to avoid any further operations
        }

        if (view === 'month') {
        return Object.values(dataFromChild).some(
            (filledDate)  => {
                return parseInt(filledDate[0]) == date.getFullYear() &&
                parseInt(filledDate[1]) == date.getMonth() + 1 &&
                parseInt(filledDate[2]) == date.getDate();
            }
        ) ? 'blue-date' : null;
        }
        return null;
    };

    return (
        <div className="employee-page">
            <div className="calendar-row">
                <div className="cal-container">
                    <Calendar
                        view="month" // Only allow month selection
                        onActiveStartDateChange={handleMonthNavigation} // Handle navigation clicks
                        showNeighboringMonth={false} // Hide neighboring months
                        tileDisabled={disableDates}
                        tileClassName={highlightBlueDates}
                    />
                </div>
                <div class="cal-container">
                    <label htmlFor="employeeSelect">Select Employee</label>
                    <select value={selectedValue} id="employeeSelect" className="employee-select" onChange={handleShow}>
                        {
                            employees.map((employee, index) => (
                                <option data-id={employee.id} value={index}>{employee.name}</option>
                            ))
                        }
                        <option value="add-employee" className="add-new">
                            + Add New Employee
                        </option>
                    </select>

                    <div class="total-container">
                        <p class="total-header">MONTHLY TOTAL</p>
                        <div class="total-row"><p class="total-paragraph">LABOR</p><p class="total-paragraph">{monthlyTotal.toFixed(2)}</p></div>
                        <div class="total-row"><p class="total-paragraph">MAT'L</p><p class="total-paragraph">{monthlyMaterials.toFixed(2)}</p></div>
                    </div>

                    <div class="total-container">
                        <p class="total-header">YTD TOTAL</p>
                        <div class="total-row"><p class="total-paragraph">LABOR</p><p class="total-paragraph">{yearlyTotal.toFixed(2)}</p></div>
                        <div class="total-row"><p class="total-paragraph">MAT'L</p><p class="total-paragraph">{yearlyMaterials.toFixed(2)}</p></div>
                    </div>

                    {showModal && (
                        <div className="modal-overlay" onClick={handleClose}>
                            <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
                                <div className="custom-modal-header">
                                    <h2>Create Employee</h2>
                                    <button className="close-button" onClick={handleClose}>
                                        &times;
                                    </button>
                                </div>
                                <div className="custom-modal-body">
                                    <label htmlFor="employeeName">Employee Name</label>
                                    <input
                                        type="text"
                                        id="employeeName"
                                        value={employeeName}
                                        onChange={(e) => setEmployeeName(e.target.value)}
                                        placeholder="Enter employee name"
                                    />

                                    <label htmlFor="hourlyRate">Hourly Rate</label>
                                    <input
                                        type="number"
                                        id="hourlyRate"
                                        value={hourlyRate}
                                        onChange={(e) => setHourlyRate(e.target.value)}
                                        placeholder="Enter hourly rate"
                                    />
                                </div>
                                <div className="custom-modal-footer">
                                    <button className="modal-button" onClick={handleClose}>
                                        Close
                                    </button>
                                    <button className="modal-button" onClick={handleSave}>
                                        Save Employee
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {console.log(selectedEmployee)}
            {selectedEmployee && selectedDate ? (
                <DaysTable
                    employee={selectedEmployee} 
                    month={selectedMonth}
                    year={selectedYear}
                    filledDatesCallback={handleChildDates}
                />
            ) : (
                <div>Please select an employee and date.</div>
            )}
        </div>
    )
}

export default EmployeePage;

import React, { useState, useEffect } from 'react';
import CustomerForm from '../../components/customerForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomersPage = () => {
    const notify = (text) => toast(text);

    const logout = () => {
        // Remove the tokens from localStorage or any other storage you are using
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    
        // Redirect the user to the login page or home page as required
        window.location.href = '/login';
    };

    return (
        <>
            

            <CustomerForm
                onSuccess={(data) => {
                    console.log('Successfully added customer.');
                }}
                onError={(error) => console.error('Failed to add customer:', error)}
                notify={notify}
                logout={logout}
            />
            <ToastContainer />
        </>
    );
};

export default CustomersPage;
import React, { useState, useRef, useEffect } from 'react';
import "./index.css";

const AnimatedGif = ({ gifSrc, firstFrameSrc, lastFrameSrc, customClass, locked, callback, job, currentTripIndex, currentStatus, buttonText }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showLastFrame, setShowLastFrame] = useState(false);
    const [gifLoaded, setGifLoaded] = useState(false);
    const [topImageLoaded, setTopImageLoaded] = useState(false);
    const [bottomImageLoaded, setBottomImageLoaded] = useState(false);

    useEffect(() => {
        // Preload the GIF
        const img = new Image();
        img.src = gifSrc;
        img.onload = () => {
            setGifLoaded(true);
        };

        const topImage = new Image();
        topImage.src = firstFrameSrc;
        topImage.onload = () => {
            setTopImageLoaded(true);
        };

        const bottomImage = new Image();
        bottomImage.src = lastFrameSrc;
        bottomImage.onload = () => {
            setBottomImageLoaded(true);
        };

    }, [gifSrc, firstFrameSrc, lastFrameSrc]);

    const handleGifClick = () => {
        console.log(locked);

        if (locked) {
            return;
        }

        if (!isPlaying && gifLoaded) {
            setIsPlaying(true);
            setShowLastFrame(false);
            setTimeout(() => {
                setIsPlaying(false);
                setShowLastFrame(true);
            }, 3000); // Adjust the timeout to match the length of your GIF animation
        }

        if (callback) {
            callback(job, currentTripIndex);
        }
    };

    const parkedImageSrc = currentStatus ? lastFrameSrc : firstFrameSrc;

    return (
        <div onClick={handleGifClick} style={{ cursor: 'pointer' }} className="animateButton buttonGlow">
            {!isPlaying && !showLastFrame && (
                <div>
                    <img src={parkedImageSrc} alt="Top frame" className={customClass} /><br />
                    <span className={buttonText !== '' ? 'buttonBadge' : ''}>{buttonText}</span>
                </div>
            )}
            {isPlaying && (
                <div>
                    <img src={gifSrc} alt="Playing GIF" className={customClass} /><br />
                    <span className={buttonText !== '' ? 'buttonBadge' : ''}>{buttonText}</span>
                </div>
            )}
            {showLastFrame && (
                <div>
                    <img src={lastFrameSrc} alt="Last frame" className={customClass} /><br />
                    <span className={buttonText !== '' ? 'buttonBadge' : ''}>{buttonText}</span>
                </div>
            )}
        </div>
    );
};

export default AnimatedGif;

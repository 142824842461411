import React, { useState } from 'react';
import './index.css';

const CustomerForm = ({ onSuccess, onError, logout, notify }) => {
    const [clientData, setClientData] = useState({
        name: '',
        description: '',
        notice: '',
        job_number_letter: '',
        logo: null
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setClientData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setClientData(prevData => ({
            ...prevData,
            [name]: files[0]
        }));
    };

    const addClient = () => {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Authorization': `Bearer ${token}`,
        });

        const formData = new FormData();
        for (const key in clientData) {
            formData.append(key, clientData[key]);
        }

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/clients/?format=json`, {
            method: 'POST',
            headers: headers,
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(() => {
            setClientData({
                name: '',
                description: '',
                notice: '',
                job_number_letter: '',
                logo: null
            });
            if (onSuccess) onSuccess();
        })
        .catch(error => {
            let errorMessage = '';
            Object.keys(error).forEach(key => {
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            if (error.code === 'token_not_valid') {
                logout();
            }

            notify(`Error adding client: ${errorMessage}`);
            if (onError) onError(error);
            console.error('Error adding client:', error);
        });
    };

    return (
        <div className="container">
            <div className="formGroup">
                <label htmlFor="name" className="label">Customer Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={clientData.name}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="description" className="label">Customer Description:</label>
                <input
                    type="text"
                    id="description"
                    name="description"
                    value={clientData.description}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="notice" className="label">Customer Notice:</label>
                <input
                    type="text"
                    id="notice"
                    name="notice"
                    value={clientData.notice}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="job_number_letter" className="label">Customer Job Number Letter:</label>
                <input
                    type="text"
                    id="job_number_letter"
                    name="job_number_letter"
                    value={clientData.job_number_letter}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="logo" className="label">Logo:</label>
                <input
                    type="file"
                    id="logo"
                    name="logo"
                    onChange={handleFileChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <button type="button" onClick={addClient} className="button">
                    Save Customer
                </button>
            </div>
        </div>
    );
}

export default CustomerForm;

import React, { useEffect } from 'react';
import SummaryTable from '../../components/summaryTable';
import JobData from '../../components/jobData';
import { useJobs } from '../../components/jobContext';
import { useSelection } from '../../components/selectionContext';
import { useNavigate } from 'react-router-dom';
import './index.css';

const HomePage = () => {
    const navigate = useNavigate();
    const { jobsData, refreshTables } = useJobs();
    const { selectedJobs } = useSelection();
    
    useEffect(() => {
        refreshTables();
    }, []);

    const formatString = (str) => {
        // Capitalize the first letter
        let formattedStr = str.charAt(0).toUpperCase() + str.slice(1);

        // Insert spaces before each capital letter except the first one
        formattedStr = formattedStr.replace(/([A-Z])/g, ' $1');

        // Remove the leading space if it exists (in case the first character is already capitalized)
        if (formattedStr.charAt(0) === ' ') {
            formattedStr = formattedStr.slice(1);
        }

        return formattedStr;
    };

    const handleNavigation = () => {
        navigate('/map', { state: { selectedJobs } });
      };

    console.log(jobsData);

  return (
    <>
        <div className="table-container">
            <SummaryTable 
                jobsInProgress={jobsData['jobsInProgress'].length} 
                bidJobs={jobsData['bids'].length}
                completedJobsNotInvoiced={jobsData['completedJobsNotInvoiced'].length}
                invoicedJobs={jobsData['invoicedJobs'].length}
                invoicedPile={jobsData['invoicedPile'].length}
                paidJobs={jobsData['paidJobs'].length}
                cancelledJobs={jobsData['cancelledJobs'].length}
            />
            {selectedJobs.length > 0 && (
                <button class="map-button" onClick={handleNavigation}>Show on Map</button>
            )}
        </div>

        {Object.keys(jobsData).map((jobType) => (
            <div className="tablesec" key={jobType}>
                <div className="section_header">{formatString(jobType)} <span className="table-total">{jobsData[jobType].length}</span></div>
                
                <JobData data={jobsData[jobType]} refreshTables={refreshTables} />
            </div>
            
        ))}
    </>
  );
};

export default HomePage;
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TokenValidationContext = createContext();

export const TokenValidationProvider = ({ children }) => {
    const navigate = useNavigate();

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/token/refresh/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refresh: refreshToken })
            });

            if (!response.ok) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                navigate('/login');
            } else {
                const data = await response.json();
                localStorage.setItem('access_token', data.access);
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            navigate('/login');
        }
    };

    useEffect(() => {
        // Refresh token on page load
        refreshToken();

        // Set an interval to refresh token every hour
        const intervalId = setInterval(refreshToken, 3600000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, [navigate]);

    return (
        <TokenValidationContext.Provider value={{ refreshToken }}>
            {children}
        </TokenValidationContext.Provider>
    );
};

export const useTokenValidation = () => {
    return useContext(TokenValidationContext);
};
